import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Meta from '../components/Meta'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { pageDetail } from '../components/uiApi'
import ReactHtmlParser from 'react-html-parser';
const PageDetail = () => {
    const [page, setPage] = useState({})
    const [error, setError] = useState(false)
    const params = useParams()
    const loadSinglePage = pageSlug => {
        pageDetail(pageSlug).then(data => {
            if (data.error) {
                setError(data.error)
            }
            else {
                setPage(data)
                
            }
        })
    }
    useEffect(() => {
        const pageSlug = params.pageSlug
        loadSinglePage(pageSlug)
    }, [params.pageSlug])
   
    return (
        <>
        {error}
        	<Meta title={page.information_name} />
            <Navbar />
            <section className="breadcrumb-area" style={{ backgroundImage: "url( '../images/resources/breadcrumb-bg.jpg')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="inner-content text-center clearfix">
                                <div className="breadcrumb-menu">
                                    <ul className="clearfix">
                                        <li><Link to="/">Home</Link></li>
                                         {(() => {
                                            if (page.information_services===1) {
                                              return (
                                                <li className="active">Services</li>
                                              )
                                            } else if (page.information_our_rugs===1) {
                                              return (
                                                <li className="active">Our Rugs</li>
                                              )
                                            } else {
                                              return (
                                                <li className="active">{page.information_name}</li>
                                              )
                                            }
                                          })()}
                                    </ul>    
                                </div>
                                <div className="title">
                                   <h1>{page.information_name}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="single-service-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="single-service-top">
                                <div className="sec-title-box">
                                    <h1>{page.information_name}</h1>
                                </div>
                                <div className="text">
                                        {(() => {
                                            if (page.information_slug==='about-us') {
                                              return (
                                                <>
                                                {ReactHtmlParser(page.information_description)}
                                                <br></br>
                                                <div className="row">
                                                     <div className="col-xl-6 col-lg-6">
                                                        <div className="single-trusted-image">
                                                            <img src={window.location.origin + '/images/resources/trusted-1.jpg'} alt="Himalayan dyed woolen"/>
                                                        </div>        
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        <div className="single-trusted-image">
                                                            <img src={window.location.origin + '/images/resources/trusted-2.jpg'} alt="Himalayan dyed woolen"/>
                                                        </div>        
                                                    </div>
                                                </div>
                                                </>
                                              )
                                            }  else if (page.information_slug==='himalayan-tibetan-wool-rugs') {
                                              return (
                                                <>
                                                {ReactHtmlParser(page.information_description)}
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/himalayan-dyed-woolen.jpg'} alt="Himalayan dyed woolen"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Dyed woolen yarn</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/himalayan-sheep.jpg'} alt="Himalayan sheep"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Sheep grazing on mountain</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/himalayan-rug.jpg'} alt="Himalayan rug"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Woolen hand knotted Rug in Traditional design</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                              )
                                            } else if (page.information_slug==='green-plant-fiber-nettle-allo-rugs') {
                                              return (
                                                <>
                                                {ReactHtmlParser(page.information_description)}
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/nettle-plant.jpg'} alt="Nettle (Allo) Plant"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Nettle (Allo) Plant</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/nettle-hand-carded.jpg'} alt="Nettle hand carded, hand spun yarn"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Nettle hand carded, hand spun yarn</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/nettle-rug.jpg'} alt="Nettle Rug in 100 knots, natural color"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Nettle Rug in 100 knots, natural color</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                              )
                                            } else if (page.information_slug==='green-plant-fiber-hemp-rugs') {
                                              return (
                                                <>
                                                {ReactHtmlParser(page.information_description)}
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/hemp-plant.jpg'} alt="Hemp Plant"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Hemp Plant</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/hemp-hand-carded.jpg'} alt="Hemp hand carded, hand spun yarn"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Hemp hand carded, hand spun yarn</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/hemp-rug.jpg'} alt="Hemp Rug in 100 knots, natural color"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Hemp Rug in 100 knots, natural color</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                              )
                                            } else if (page.information_slug==='green-plant-fiber-banana-silk-rugs') {
                                              return (
                                                <>
                                                {ReactHtmlParser(page.information_description)}
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/banana-tree.jpg'} alt="Banana Tree"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Banana Tree</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/banana-silk-dyed.jpg'} alt="Banana Silk dyed yarn"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Banana Silk dyed yarn</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/banana-silk-rug.jpg'} alt="Natural color and dyed Banana Silk rugs"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Natural color and dyed Banana Silk rugs</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                              )
                                            } else if (page.information_slug==='natural-chinese-silk-rugs') {
                                              return (
                                                <>
                                                {ReactHtmlParser(page.information_description)}
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/chinese-silk-1.jpg'} alt="Cocoon of silk"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Cocoon of silk</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/chinese-silk-ball.jpg'} alt="Dyed silk balls"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Dyed silk balls</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                   <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="single-blog-post mxwidth">
                                                            <div className="img-holder">
                                                                <img src={window.location.origin + '/images/rugs/chinese-silk-rug.jpg'} alt="Silk Rug in 80 Knots"/>
                                                            </div>
                                                            <div className="text-holder">
                                                                <h3 className="blog-title"><a href="news-single.html">Silk Rug in 80 Knots</a></h3> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                              )
                                            } else {
                                              return (
                                                <>
                                                {ReactHtmlParser(page.information_description)}
                                                </>
                                              )
                                            }
                                          })()}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  
            <Footer />
        </>
    )
}

export default PageDetail
