import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import Meta from '../components/Meta'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { listSettings } from '../components/uiApi'
const Contactform = () => {
    const [settinglist, setSettingList] = useState([])
    const [error,setError]=useState(false)
    const loadSettingList = () => {
        listSettings().then(data => {
                if (data.error) {
                    setError(data.error)
                }
                else {
                    setSettingList(data)
                }
        })
    }
    useEffect(() => {
        loadSettingList()
    }, [])
    return (
        <>
            <Meta title="Contact us" />
            <Navbar />
            <section className="breadcrumb-area" style={{ backgroundImage: "url( '../images/resources/breadcrumb-bg.jpg')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="inner-content text-center clearfix">
                                <div className="breadcrumb-menu">
                                    <ul className="clearfix">
                                        <li><Link to="/">Home</Link></li>
                                        <li className="active">Contact us</li>
                                    </ul>    
                                </div>
                                <div className="title">
                                   <h1>Contact us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-info-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                {error}
                                <div className="contact-info-content">
                                    <div className="sec-title">
                                        <h3>Contact With Us</h3>
                                        <div className="title">Write Us a Message</div>
                                    </div>
                                    <div className="inner-content">
                                        <div className="text">
                                            <p>If you have reviewed our Site and want to contact us, contact our helpdesk.</p>
                                        </div>
                                        <ul>
                                            <li>
                                                <p>Send Email</p>
                                                {settinglist && settinglist.length>0 ? (
                                                <>
                                                    { settinglist[3].setting_slug==='email-address' ? (
                                                        <>
                                                        <span>{ settinglist[3].setting_text }</span>
                                                        </>
                                                    ) : (
                                                        <span>info@siddharthacarpethouse.com.np, sidharthacarpet@yahoo.com</span>
                                                    )}
                                                </>
                                            ) : ('')}
                                            </li>
                                            <li>
                                                <p>Visit Us</p>
                                                {settinglist && settinglist.length>0 ? (
                                            <>
                                                { settinglist[4].setting_slug==='contact-address'? (
                                                    <>
                                                        <span>{ settinglist[4].setting_text }</span>
                                                    </>
                                                ) : (
                                                        <span>Swoyambhu, Kathmandu, Nepal</span>
                                                )}
                                            </>
                                        ) : ('')}
                                            </li>
                                        </ul>
                                    </div>
                                </div>    
                            </div>
                            {/* 
                            <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12">
                                <div className="contact-form">
                                    <form id="contact-form" name="contact_form" class="default-form">
                                        {showError()}
                                        {showSuccess()}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-box">   
                                                    <input type="text" name="form_name" value="" placeholder="Your Name" required="" onChange={handleChange('form_name')} value={form_name}/>
                                                    <div className="icon">
                                                        <i className="fa fa-user" aria-hidden="true"></i>
                                                    </div>
                                                </div>    
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-box"> 
                                                    <input type="email" name="form_email" value="" placeholder="Email Address" required="" onChange={handleChange('form_email')} value={form_email}/>
                                                    <div className="icon">
                                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                                    </div>
                                                </div>    
                                            </div>    
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-box"> 
                                                    <input type="text" name="form_phone" value="" placeholder="Phone" onChange={handleChange('form_phone')} value={form_phone}/>
                                                    <div className="icon">
                                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                                    </div>
                                                </div>    
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-box"> 
                                                    <input type="text" name="form_subject" value="" placeholder="Subject" onChange={handleChange('form_subject')} value={form_subject}/> 
                                                    <div className="icon">
                                                        <i className="fa fa-file-text" aria-hidden="true"></i>
                                                    </div>
                                                </div>    
                                            </div>      
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-box">    
                                                    <textarea name="form_message" placeholder="Your Message.." required="" onChange={handleChange('form_message')} value={form_message} />
                                                    <div className="icon">
                                                        <i className="fa fa-comment" aria-hidden="true"></i>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="button-box">
                                                    <button className="btn-one" type="submit" data-loading-text="Please wait..." onClick={handleSubmit}>Submit Here</button>    
                                                </div>     
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    )
}

export default Contactform
