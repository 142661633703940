import {API} from '../config'

//Page detail
export const pageDetail=(pageSlug)=>{
    return fetch(`${API}/api/page/${pageSlug}`,{
        method:'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        }
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}

//List Pages
export const listPages=()=>{
    return fetch(`${API}/api/informationlist`,{
        method:'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        }    
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//List Settings
//Get Setting
export const listSettings=()=>{
    return fetch(`${API}/api/settinglist`,{
        method:'GET' ,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        }      
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//To fetch medias by arrival date in desc and limit value
export const getMedias=(sortBy)=>{
    return fetch(`${API}/api/medialist?sortBy=${sortBy}&order=desc&limit=8`,{
        method:'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        }
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}

//To fetch Gallery by arrival date in desc and limit value
export const getGallery=(sortBy)=>{
    return fetch(`${API}/api/gallerylist?sortBy=${sortBy}&order=asc&limit=8`,{
        method:'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        }
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}


//Media details
export const mediasDetails=(mediaId)=>{
    return fetch(`${API}/api/mediadetails/${mediaId}`,{
        method:'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        }
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}

