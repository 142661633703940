import { API } from "../../config";
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import AdminSidebar from './AdminSidebar'
import {isAuthenticated} from '../auth'
import { mediasDetails, updateMedia, uploadmediaimg, deleteuploadFileMedia } from './apiIndex'

const AddMedia = () => {
    const params = useParams()
    const mediaId = params.mediaId
    const [media_name, setMediaName] = useState('')
    const [media_description, setMediaDescription] = useState('')
    const [media_image, setMediaImage] = useState('')
    const [media_isSlider, setisSlider] = useState('')
    const [isCheckedS, setIsCheckedS] = useState(false);
    const [media_isGallery, setisGallery] = useState('')
    const [isCheckedG, setIsCheckedG] = useState(false);
    const [error,setError]=useState(false)
    const [success,setSuccess]=useState(false)
    //destructure token and user from localStorage
    const {token} =isAuthenticated()
    const loadUpdateMedia = mediaId => {
        mediasDetails(mediaId).then(data => {
                setMediaName(data.media_name)
                setMediaDescription(data.media_description)
                setMediaImage(data.media_image)
                setisSlider(data.media_isSlider)
                if(data.media_isSlider.toString()==='1'){
                    setIsCheckedS(!isCheckedS);
                } else {
                    setIsCheckedS(isCheckedS);
                }
                setisGallery(data.media_isGallery)
                if(data.media_isGallery.toString()==='1'){
                    setIsCheckedG(!isCheckedG);
                } else {
                    setIsCheckedG(isCheckedG);
                }
        })
    }

    useEffect(() => {
        const mediaId = params.mediaId
        loadUpdateMedia(mediaId)
    }, [params.mediaId])

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('media_image', file)
        uploadmediaimg(token,`${mediaId}`,formData)
            .then(data=>{
                if(data.error){
                    setError(data.error)
                }
                else{
                    setError('')
                    setSuccess(true)
                    setMediaImage(data)
                }
        })
  }
   
    const submitHandler = (e) => {
        e.preventDefault()
        setError('')
        setSuccess(false)
        //make request to add category

        updateMedia(token,`${mediaId}`,{
            media_name:media_name,
            media_description:media_description,
            media_image:media_image,
            media_isSlider:media_isSlider,
            media_isGallery:media_isGallery
        })
        .then(data=>{
            if(data.error){
                setError(data.error)
            }
            else{
                setError('')
                setSuccess(true)
                setMediaName(media_name)
                setMediaDescription(media_description)
                setMediaImage(media_image)
                setisSlider(media_isSlider)
                setisGallery(media_isGallery)
            }
        })
      }
    const RemoveuploadFileHandler = (id) => {
        if (window.confirm('Are you sure')) {
            setError('')
            setSuccess(false)
            //make request to add category
            deleteuploadFileMedia(token,`${id}`)
            .then(data=>{
                if(data.error){
                    setError(data.error)
                }
                else{
                    setError('')
                    setSuccess(true)
                    setMediaImage(data)
                }
            })
        }
    }
    const handleOnChangeS = (e) => {
        if(isCheckedS===false){
            setisSlider(1)
        } else {
            setisSlider(0)
        }
        setIsCheckedS(!isCheckedS);
    }

    const handleOnChangeG = (e) => {
       
        if(isCheckedG===false){
            setisGallery(1)
        } else {
            setisGallery(0)
        }
        setIsCheckedG(!isCheckedG);
    }
      // to show error msg
    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       Success: You have modified Media!
    </div>
    )
    return (
        <>
        <div className="container dashboard-wrapper">
            <div className="row">
                <div className="col-md-3">
                    <AdminSidebar/>
                </div>
                <div className="col-md-6 adminpage-container">
                    <h3>Edit Media</h3>
                    <form className="shadow-lg p-3">
                        {showError()}
                        {showSuccess()}
                        <div className="mb-3">
                            <label htmlFor="medianame">Name</label>
                            <input type="text" id="medianame" className="form-control"
                            value={media_name} onChange={(e) => setMediaName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description">Description</label>
                           <textarea className="form-control" id="description"
                           onChange={(e) => setMediaDescription(e.target.value)} value={media_description}
                           ></textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="image">Image</label><br />
                            {media_image.length>0 ? (
                            <>
                            <img src={`${API}/${media_image}`} alt={media_name} className="img-fluid" width="130" />
                            <button onClick={() => RemoveuploadFileHandler(mediaId)}>Remove Image</button>
                            </>
                            ) : (<img src={`${API}/public/uploads/default-placeholder.png`} alt={media_name} className="img-fluid" width="130" />
                            )}
                            <input type="file" id="image" className="form-control" accept="image/*"
                            onChange={uploadFileHandler}
                             />
                        </div>
                        <div className="mb-3">
                            <input type="checkbox" id="media_isSlider" value={media_isSlider} checked={isCheckedS} onChange={handleOnChangeS}
                            /> 
                            Is Slider
                          </div>
                          <div className="mb-3">
                            <input type="checkbox" id="media_isGallery" value={media_isGallery} checked={isCheckedG} onChange={handleOnChangeG}
                            /> 
                            Is Gallery
                          </div>
                       <div className="mb-3">
                           <button className="btn btn-primary" onClick={submitHandler}>Edit Media</button>
                       </div>

                    </form>
                </div>
            </div>
        </div>
            
        </>
    )
}

export default AddMedia
