import React, {useEffect} from 'react'
import MyRoute from "./MyRoute";
import "./App.css";
const App = () => {
 useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <>
    <div className="boxed_wrapper">
  		<div className="preloader"></div> 
      	<MyRoute />
    </div> 
    </>
  );
}

export default App;
