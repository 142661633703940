import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { forgetpassword } from './index'
import Meta from '../../components/Meta'

const ForgetPassword = () => {

    const [values, setValues] = useState({
        email: '',
        error: '',
        success: ''
    })

    const { email, error, success } = values

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        setValues({ ...values, error: false })
        //forgetpassword function
        forgetpassword({ email })
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, success: false })
                }
                else {
                    setValues({ ...values, email: '', success: true })
                }
            })
    }

    // to show error msg
    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: success ? '' : 'none' }}>
            Password reset link has been sent to your mail
        </div>
    )


    return (
        <>
           <Meta title="ForgetPassword" />
            <div className="admin-wrapper">
                <div className="logo">
                    <img src={window.location.origin + '/images/favicon/apple-touch-icon.png'} alt="Main Logo"/>
                </div>
                <div className="text-center mt-4 name">
                    Siddhartha Carpet House
                </div>
                <form className="p-3 mt-3">
                    {showError()}
                    {showSuccess()}
                    <div className="form-field d-flex align-items-center">
                        <span className="fa fa-user"></span>
                        <input type="email" name="email" id="email" placeholder="example@gmail.com" className="form-control" onChange={handleChange('email')} value={email} />
                    </div>
                    <button className="btn mt-3" onClick={handleSubmit}>Send Link</button>
                </form>
                <div className="text-center fs-6">
                    <Link to="/login" className="text-decoration-none text-secondary">Login?</Link>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword
