import React,{useState, useEffect} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { isAuthenticated,logout } from '../auth'
import { userDetails } from './apiIndex'
const AdminSidebar = () => {
	const [name, setName] = useState('')
	let pathname = window.location.pathname;
    //destructure token and user from localStorage
    const {user:{_id}, token} =isAuthenticated()
    const loadUpdateUser = _id => {
        userDetails(token,_id).then(data => {
                setName(data.name)
        })
    }
    useEffect(() => {
    	pathname = window.location.pathname;
        const {user:{_id}, token} =isAuthenticated()
        const loadUpdateUser = _id => {
            userDetails(token,_id).then(data => {
                    setName(data.name)
            })
        }
        const Id = _id
        loadUpdateUser(Id)
    }, [_id,window.location.pathname])
    const navigate=useNavigate()
    return (
        <>
            <div className="admin-sidebar d-flex flex-column flex-shrink-0 mt-3 p-3 text-white bg-dark">
                <Link to="/admin/dashboard" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-4" style={{marginLeft:'15px'}}>Dashboard</span>
                </Link>
                <hr/>
                <ul className="nav nav-pills flex-column mb-auto">
                    <li className={`${pathname.match('/dashboard') ? 'current' : ''}`}>
                        <Link to="/admin/dashboard" className="nav-link text-white">
                            Home
                        </Link>
                    </li>
                     <li className={`${pathname.match('/allmedia') || pathname.match('/addmedia') || pathname.match('/editmedia') ? 'current' : ''}`}>
                        <Link to="/admin/allmedia" className="nav-link text-white">
                            Media
                        </Link>
                    </li>
                    <li className={`${pathname.match('/allinformations') || pathname.match('/addinformation') || pathname.match('/editinformation')? 'current' : ''}`}>
                        <Link to="/admin/allinformations" className="nav-link text-white">
                            Pages
                        </Link>
                    </li>
                                           
                    <li className={`${pathname.match('/allsettings') || pathname.match('/addsetting') || pathname.match('/editsetting') ? 'current' : ''}`}>
                        <Link to="/admin/allsettings" className="nav-link text-white">
                           Setting
                        </Link>
                    </li>
                </ul>
                <hr/>
                    <Link to="/" className="nav-link text-white">
                    Visit Site
                    </Link>  
                    <div className="dropdown">
                        <Link to="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                                <strong>{`${name}`}</strong>
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                           
                            <li><Link className="dropdown-item" to={`/admin/edituser/${_id}`}>Profile</Link></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li>
                                {
                                    isAuthenticated() && (
                                        <button className='btn btn-outline-warning mt-2'
                                            onClick={() => logout(() => {
                                                navigate('/login')
                                            })}>
                                            Logout
                                        </button>
                                    )
                                }
                            </li>
                        </ul>
                    </div>
            </div>
        </>
        )
}
export default AdminSidebar
