import React,{useEffect} from 'react'
import { BrowserRouter as Router,Route,Routes,useLocation } from 'react-router-dom'
import Confirm from './pages/auth/Confirm'
import Login from './pages/auth/Login'
import HomePage from './pages/HomePage'
import PageDetail from './pages/PageDetail'
import Gallery from './pages/Gallery'
import Contactform from './pages/Contactform'
import AdminRoute from './pages/auth/AdminRoute'
import ForgetPassword from './pages/auth/ForgetPassword'
import ResetPassword from './pages/auth/ResetPassword'

//Admin Dashboard
import AdminDashboard from './pages/admin/AdminDashboard'
import AddMedia from './pages/admin/AddMedia'
import EditMedia from './pages/admin/EditMedia'
import AllMedias from './pages/admin/AllMedias'


import AddInformation from './pages/admin/AddInformation'
import EditInformation from './pages/admin/EditInformation'
import AllInformations from './pages/admin/AllInformations'
import AddSetting from './pages/admin/AddSetting'
import EditSetting from './pages/admin/EditSetting'
import AllSettings from './pages/admin/AllSettings'
import AllUsers from './pages/admin/AllUsers'
import EditUser from './pages/admin/EditUser'

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const MyRoute = () => {
   
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={ <HomePage/> } />
                    <Route path="/page/:pageSlug" element={<PageDetail/>} />
                    <Route path="/login" element={ <Login/> } />
                    <Route path="/gallery" element={ <Gallery/> } />
                    <Route path="/contact-us" element={ <Contactform/> } />
                    <Route path="/email/confirmation/:token" element={ <Confirm/> } /> 
                    <Route path="/forgetpassword" element={<ForgetPassword/>} />
                    <Route path="/reset/password/:token" element={<ResetPassword/>} />

                 {/* admin */}
                 <Route path="/" element={<AdminRoute/>} >
                     <Route path="/admin/dashboard" element={<AdminDashboard/>}/>
                     <Route path="/admin/addmedia" element={<AddMedia/>} />
                     <Route path="/admin/editmedia/:mediaId" element={<EditMedia/>} />
                     <Route path="/admin/allmedia" element={<AllMedias/>} />
                     <Route path="/admin/addinformation" element={<AddInformation/>} />
                     <Route path="/admin/editinformation/:informationId" element={<EditInformation/>} />
                     <Route path="/admin/allinformations" element={<AllInformations/>} />
                     <Route path="/admin/addsetting" element={<AddSetting/>} />
                     <Route path="/admin/editsetting/:settingId" element={<EditSetting/>} />
                     <Route path="/admin/allsettings" element={<AllSettings/>} />
                     <Route path="/admin/allusers" element={<AllUsers/>} />
                     <Route path="/admin/edituser/:userId" element={<EditUser/>} />
                 </Route>
            </Routes>
        </Router>
    )
}

export default MyRoute
