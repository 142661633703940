import React,{useState,useEffect} from 'react'
import AdminSidebar from './AdminSidebar'
import {isAuthenticated} from '../auth'
import { addmedia } from './apiIndex'

const AddMedia = () => {
    const {token} =isAuthenticated()
    const [isCheckedS, setIsCheckedS] = useState(false);
    const [isCheckedG, setIsCheckedG] = useState(false);
    const [isSlider_value,setisSlider]=useState({
        media_isSlider:0,
    })
    const [isGallery_value,setisGallery]=useState({
        media_isGallery:0,
    })
    const [values,setValues]=useState({
        media_name:'',
        media_description:'',
        media_image:'',
        error:'',
        success:false,
        formData:''
    })
    const {media_isSlider} = isSlider_value
    const {media_isGallery} = isGallery_value
    const {media_name,media_description,error,success,formData} = values
    
    //load set form data
     const init=()=>{
         setValues({...values,formData:new FormData})
     }
     //to send form data
     useEffect(()=>{
         init()
     },[])

     const handleOnChangeS = (e) => {
        if(isCheckedS===false){
            const name='media_isSlider'
            formData.set(name,1)
            setisSlider({...isSlider_value,[name]:1})
        } else {
            const name='media_isSlider'
            formData.set(name,0)
            setisSlider({...isSlider_value,[name]:0})
        }
        setIsCheckedS(!isCheckedS);
    }

    const handleOnChangeG = (e) => {

        if(isCheckedG===false){
            const name='media_isGallery'
            formData.set(name,1)
            setisGallery({...isGallery_value,[name]:1})

        } else {
            const name='media_isGallery'
            formData.set(name,0)
            setisGallery({...isGallery_value,[name]:0})
        }
        setIsCheckedG(!isCheckedG);
    }

    const handleChange=name=>event=>{
         const value = name.toString()==='media_image' ? event.target.files[0] :event.target.value
         formData.set(name,value)
         setValues({...values,[name]:value})
     }

     const handleSubmit=e=>{
         e.preventDefault()
         setValues({...values, error:''})
         addmedia(token,formData)
         .then(data => {
             if(data.error){
                 setValues({...values,error:data.error})
             }
             else{
                 setValues({...values,media_name:'',media_description:'',media_image:'',success:true,error:''})
             }
         })
     }

      // to show error msg
    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       New media added
    </div>
    )
    return (
        <>
        <div className="container dashboard-wrapper" >
            <div className="row">
                <div className="col-md-3">
                    <AdminSidebar/>
                </div>
                <div className="col-md-6 adminpage-container">
                    <h3>Add Media</h3>
                    <form className="shadow-lg p-3">
                        {showError()}
                        {showSuccess()}
                        <div className="mb-3">
                            <label htmlFor="medianame">Name</label>
                            <input type="text" id="medianame" className="form-control"
                            onChange={handleChange('media_name')} value={media_name}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description">Description</label>
                           <textarea className="form-control" id="description"
                           onChange={handleChange('media_description')} value={media_description}
                           ></textarea>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="image">Image</label>
                            <input type="file" id="image" className="form-control" accept="image/*"
                            onChange={handleChange('media_image')} 
                             />
                        </div>

                        <div className="mb-3">
                            <input type="checkbox" id="media_isSlider" value={media_isSlider} checked={isCheckedS} onChange={handleOnChangeS}
                            /> 
                            Is Slider
                          </div>
                        <div className="mb-3">
                            <input type="checkbox" id="media_isGallery" value={media_isGallery} checked={isCheckedG} onChange={handleOnChangeG}
                            /> 
                            Is Gallery
                          </div>

                       <div className="mb-3">
                           <button className="btn btn-primary" onClick={handleSubmit}>Add Media</button>
                       </div>

                    </form>
                </div>
            </div>
        </div>
            
        </>
    )
}

export default AddMedia
