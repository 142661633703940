import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { API } from "../../config";
import { isAuthenticated } from '../auth'
import AdminSidebar from './AdminSidebar'
import Pagination from "../../components/Pagination";
import { deleteSetting } from './apiIndex'

const AllSettings = () => {
    const [settinglist, setSettingList] = useState([]);
    const [error, setError] = useState(false);
    const [success,setSuccess]=useState(false)

    const params = useParams()
    const pageNumber = params.pageNumber || 1;
    const [page, setPage] = useState(pageNumber);
    const [pages, setPages] = useState(1);
    const [pageSize, setPageSize] = useState('');
    const [total, setTotal] = useState('');

    const {token} =isAuthenticated()

    const start = (() => { 
        if(page>1)
            { 
                return (pageSize * (page -1))
            } 
            return 1; 
    })();

    const end = (() => { 
        if(page>1){ 
            if(page.toString()===pages){
                return total
            }
            return (pageSize + start)
        } 
        return pageSize; 
    })();

     
    const loadSettingList = async() => {
                const res = await fetch(`${API}/api/allsettinglist?page=${page}`);
                if(res.ok) {
                    const { data, pages: totalPages, total, pageSize } = await res.json();
                    if (data.error) {
                        console.log(data.error)
                    } else {
                        setPageSize(pageSize);
                        setPages(totalPages);
                        setTotal(total);
                        setSettingList(data);
                    }
                } else {
                        const res = await fetch(`${API}/api/allsettinglist?page=${page-1}`);
                        if(res.ok) {
                                const { data, pages: totalPages, total, pageSize } = await res.json();
                                if (data.error) {
                                    console.log(data.error)
                                } else {
                                    setPageSize(pageSize);
                                    setPage(page-1);
                                    setPages(totalPages);
                                    setTotal(total);
                                    setSettingList(data);
                                }
                        } else {
                                const res = await fetch(`${API}/api/settinglist`);
                                if(res.ok) {
                                     const { data } = await res.json();
                                    setSettingList(data);
                                } else {
                                    setError('')
                                    setSuccess(false)
                                }
                        }
                }
    }

    useEffect(() => {
        loadSettingList()
    }, [page])


    const deleteHandler = (id) => {
        if (window.confirm('Are you sure')) {
            setError('')
            setSuccess(false)
            //make request to add category
            deleteSetting(token,`${id}`)
            .then(data=>{
                if(data.error){
                    setError(data.error)
                }
                else{
                    setError('')
                    setSuccess(true)
                    loadSettingList()
                }
            })
        }
      }
    // to show error msg
    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       Success: You have modified settings!
    </div>
    )
    return (
        <>
            <div className="container dashboard-wrapper">
                <div className="row">
                    <div className="col-md-3">
                        <AdminSidebar />
                    </div>
                    <div className="col-md-9 adminpage-container">
                        {showError()}
                        {showSuccess()}
                        <div className="headerwrap">
                        <Link to="/admin/addsetting" className="btn btn-primary">Add Setting</Link>
                        <h6 className="text-center text-muted m-3 pull-right">
                            Showing {start} to {end} of {total} ({pages} Pages)
                        </h6>
                        </div>
                        <hr />
                        <div className="table-responsive">
                        <table className="table table-bordered table-secondary">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{width: '33%'}}>Slug</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                           {settinglist && settinglist.length>0 ? (
                            <tbody>
                                {settinglist.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item.setting_name}</td>
                                        <td>{item.setting_slug}</td>
                                        <td>
                                            <Link to={`/admin/editsetting/${item._id}`} className="btn btn-warning">
                                                Update
                                            </Link>
                                            { item.setting_permanent!='1' ? (
                                            <>
                                            <Link to="/admin/allsettings" className="btn btn-danger" onClick={() => deleteHandler(item._id)}>
                                                Delete
                                            </Link>
                                            </>
                                            ) : ('')}
                                        </td>
                                    </tr>
                                    ))}
                            </tbody>
                            ) : (
                               <tbody>
                                    <tr><td colSpan={3} style={{textAlign:"center"}}>No results!</td></tr>
                                 </tbody>
                            )}
                        </table>
                        </div>
                        <Pagination page={page} pages={pages} changePage={setPage} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default AllSettings
