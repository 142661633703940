import {API} from '../../config'

//for signup
export const signup=(user)=>{
    return fetch(`${API}/api/register`,{
        method:"POST",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json"
        },
        body:JSON.stringify(user)
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//login
export const login=(user)=>{
    return fetch(`${API}/api/login`,{
        method:"POST",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json"
        },
        body:JSON.stringify(user)
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}
//authenticate
export const authenticate=(data,next)=>{
    if(typeof window !=='undefined'){
        localStorage.setItem('jwt',JSON.stringify(data))
        next()
    }
}

//redirect by user role if authenticated
export const isAuthenticated=()=>{
    if(typeof window === undefined){
        return false
    }
    if(localStorage.getItem('jwt')){
        return JSON.parse(localStorage.getItem('jwt'))
    }
    else{
        return false
    }
}

//logout
export const logout=(next)=>{
    if(typeof window !=='undefined'){
        localStorage.removeItem('jwt',JSON.stringify('jwt'))
        next()
        return fetch(`${API}/api/logout`,{
            method:'POST'
        })
        .then(res=>{
            console.log('logout',res)
        })
        .catch(err=>console.log(err))
    }
}

//forget password
export const forgetpassword=(user)=>{
    return fetch(`${API}/api/forgetpassword`,{
        method:"POST",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json"
        },
        body:JSON.stringify(user)
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}
