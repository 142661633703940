import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { API } from '../config'
import Meta from '../components/Meta'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { getGallery } from '../components/uiApi'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
const Gallery = () => {
  const [images, setGallery] = useState([])
    const [error, setError] = useState(false)
    
    const loadGallery = () => {
        getGallery('createdAt').then(data => {
            if (data.error) {
                setError(data.error)
            }
            else {
                setGallery(data)
            }
        })
    }
       
    useEffect(() => {
        loadGallery()
    }, [])

    
    return (
        <>
        {error}
          <Meta title="Photo Gallery" />
            <Navbar />
           
            <section className="breadcrumb-area" style={{ backgroundImage: "url( '../images/resources/breadcrumb-bg.jpg')" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="inner-content text-center clearfix">
                                <div className="breadcrumb-menu">
                                    <ul className="clearfix">
                                        <li><Link to="/">Home</Link></li>
                                         <li className="active">Gallery</li>
                                    </ul>    
                                </div>
                                <div className="title">
                                   <h1>Gallery</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-gallery-area">
              <div className="container">
                 <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                            <Masonry>
                                {images.map((image, i) => (
                                  <div key={i} style={{ padding: "7px" }}>
                                          <img 
                                            src={`${API}/${image.media_image}`} 
                                            alt={image.slider_name} 
                                            style={{
                                              width: "100%",
                                              display: "block",
                                              marginBottom: "5px",
                                              borderRadius: "5px"
                                            }}
                                            />
                                    </div>
                                ))}
                    </Masonry>
                  </ResponsiveMasonry>
                  </div>
              </section>
          <Footer />
        </>
    )
}

export default Gallery
