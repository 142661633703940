import React, {lazy, Suspense} from 'react'
import { Link } from 'react-router-dom'
const Carousel = lazy(() =>import("../components/Carousel"));
const Navbar = lazy(() =>import("../components/Navbar"));
const Footer = lazy(() =>import("../components/Footer"));
const HomePage = () => {
    return (
        <>
        <Suspense fallback={<></>}>
            <Navbar />
            <Carousel />
                <section className="welcome-area secpd1">
                <div className="container">
                    <div className="sec-title text-center">
                        <h3>Our Introduction</h3>
                        <div className="title">Welcome to Siddhartha Carpet House</div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="welcome-image-box clearfix">
                                <div className="image-box">
                                    <img src={'./images/resources/welcome.jpg'} alt="Siddhartha Carpet House"/>
                                </div>
                                <div className="image-thumb1">
                                    <img src={'./images/resources/welcome-thumb-1.jpg'} alt="Siddhartha Carpet House"/>
                                </div>
                                <div className="image-thumb2">
                                    <img src={'./images/resources/welcome-thumb-2.jpg'} alt="Siddhartha Carpet House"/>
                                </div>
                                <div className="icon-box">
                                    <div className="inner">
                                        <img src={'./images/icon/2.png'} alt="Siddhartha Carpet House"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="welcome-content-box">
                                <div className="top">
                                    <div className="icon">
                                        <img src={'./images/icon/1.png'} alt="Siddhartha Carpet House"/>
                                    </div>
                                    <div className="title">
                                        <h2>Manufacturer hand knotted Nepalese rugs</h2>
                                    </div>
                                </div>
                                <div className="text">
                                    <p>Siddhartha Carpet House produces fine export quality hand knotted rugs in different knots quality under strict inspection and supervision of Nepal Goodweave Foundation. It inspects and certifies the rug industry of child labor free. <br></br><br></br>We practice making every product environmentally friendly. Siddhartha Carpet House believes into “Fair Share”. Our weavers and other technical staffs are receiving fair wages.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-style1-area secpd1">
                <div className="container">
                    <div className="sec-title text-center">
                        <h3>Our Rugs</h3>
                        <div className="text"><p>Nepalese people have been making hand knotted rugs for centuries for household use. After the Tibetan refugee’s entrance into Nepal, the hand knotted rugs became an exportable products. The Nepalese-Tibetan rugs are unique in their knotting system. It has high density of knots. Himalayan Rugs manufacture and offer you the following rug products.</p></div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12"> 
                            <div className="single-service-style1 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner">
                                    <div className="img-holder">
                                        <img src={'./images/services/1.jpg'} alt="Siddhartha Carpet House"/>   
                                    </div>
                                </div>
                                <div className="text-holder text-center">
                                    <div className="button">
                                        <Link to="/page/himalayan-tibetan-wool-rugs"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>    
                                    </div>
                                    <h4><Link to="/page/himalayan-tibetan-wool-rugs">Himalayan wool rugs</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12"> 
                            <div className="single-service-style1 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div className="inner">
                                    <div className="img-holder">
                                        <img src={'./images/services/2.jpg'} alt="Siddhartha Carpet House"/>   
                                    </div>
                                </div>
                                <div className="text-holder text-center">
                                    <div className="button">
                                        <Link to="/page/green-plant-fiber-nettle-allo-rugs"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>    
                                    </div>
                                    <h4><Link to="/page/green-plant-fiber-nettle-allo-rugs">Green plant fiber - Nettle (allo) rugs</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12"> 
                            <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="inner">
                                    <div className="img-holder">
                                        <img src={'./images/services/3.jpg'} alt="Siddhartha Carpet House"/>   
                                    </div>
                                </div>
                                <div className="text-holder text-center">
                                    <div className="button">
                                        <Link to="/page/green-plant-fiber-hemp-rugs"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>    
                                    </div>
                                    <h4><Link to="/page/green-plant-fiber-hemp-rugs">Green plant fiber - Hemp rugs</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12"> 
                            <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="inner">
                                    <div className="img-holder">
                                        <img src={'./images/services/4.jpg'} alt="Siddhartha Carpet House"/>   
                                    </div>
                                </div>
                                <div className="text-holder text-center">
                                    <div className="button">
                                        <Link to="/page/green-plant-fiber-banana-silk-rugs"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>    
                                    </div>
                                    <h4><Link to="/page/green-plant-fiber-banana-silk-rugs">Green plant fiber - Banana silk rugs</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12"> 
                            <div className="single-service-style1 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="inner">
                                    <div className="img-holder">
                                        <img src={'./images/services/5.jpg'} alt="Siddhartha Carpet House"/>  
                                    </div>
                                </div>
                                <div className="text-holder text-center">
                                    <div className="button">
                                        <Link to="/page/natural-chinese-silk-rugs"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>    
                                    </div>
                                    <h4><Link to="/page/natural-chinese-silk-rugs">Natural chinese silk rugs</Link></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            </Suspense>
        </>
    )
}

export default HomePage
