import React, { useState, useEffect } from 'react'
import AdminSidebar from './AdminSidebar'
import { isAuthenticated } from '../auth'
import { allmedias,allgallery,allinformations,allsettings} from './apiIndex'
const AdminDashboard = () => {
  const [medias, setMedias] = useState([])
  const [gallery, setGallery] = useState([])
  const [informations, setInformations] = useState([])
  const [settings, setSettings] = useState([])
 
  useEffect(() => {
      //destructure token and user from localStorage
      const {token} =isAuthenticated()
      const loadInformations = () => {
          allinformations().then(data => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    setInformations(data)
                }
            })
      }

      const loadMedias = () => {
            allmedias().then(data => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    setMedias(data)
                }
            })
        }

       const loadGallery = () => {
            allgallery().then(data => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    setGallery(data)
                }
            })
        }

      const loadSettings = () => {
          allsettings(token).then(data => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    setSettings(data)
                }
            })
      }
      loadInformations()
      loadMedias()
      loadSettings()
      loadGallery()
  }, [])
	let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return (
        <>
        <div className="container dashboard-wrapper">
            <div className="row">
                <div className="col-md-3">
                    <AdminSidebar/>
                </div>
                <div className="col-md-8 adminpage-container">
                      <div className="row">
                        <div className="col-md-12 grid-margin">
                          <div className="row">
                            <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                              <h3 className="font-weight-bold">Welcome to Dashboard</h3>
                            </div>
                            <div className="col-12 col-xl-4">
                              <div className="justify-content-end d-flex">
                              <div className="flex-md-grow-1 flex-xl-grow-0">
                                <button className="btn btn-sm btn-light bg-white" type="button">
                                  <i className="mdi mdi-calendar"></i> Today ({new Date().toLocaleString('en-US',options) + ""})
                                </button>
                                
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 grid-margin transparent">
                          <div className="row">
                            <div className="col-md-6 mb-3 stretch-card transparent">
                              <div className="card card-tale">
                                <div className="card-body">
                                  <p className="mb-4">Media Images</p>
                                  <p className="fs-30 mb-2">{medias.length}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3 stretch-card transparent">
                              <div className="card card-light-purple">
                                <div className="card-body">
                                  <p className="mb-4">Gallery Images</p>
                                  <p className="fs-30 mb-2">{gallery.length}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3 stretch-card transparent">
                              <div className="card card-dark-blue">
                                <div className="card-body">
                                  <p className="mb-4">Total Pages</p>
                                  <p className="fs-30 mb-2">{informations.length}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3 stretch-card transparent">
                              <div className="card card-light-danger">
                                <div className="card-body">
                                  <p className="mb-4">Settings</p>
                                  <p className="fs-30 mb-2">{settings.length}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
            
        </>
    )
}

export default AdminDashboard
