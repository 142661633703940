import { API } from "../../config";
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import AdminSidebar from './AdminSidebar'
import {isAuthenticated} from '../auth'
import { informationsDetails, updateInformation, uploadinformationimg, deleteuploadFileInformation } from './apiIndex'

import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from '../../components/editor/EditorToolbar'
import 'react-quill/dist/quill.snow.css'
import '../../components/editor/TextEditor.css'
const EditInformation = () => {
    const params = useParams()
    const informationId = params.informationId
    const [information_name, setInformationName] = useState('')
    const [information_slug, setInformationSlug] = useState('')
    const [information_description, setInformationDescription] = useState('')
    const [information_image, setInformationImage] = useState('')
    const [information_services, setInformationServices] = useState('')
    const [isCheckedS, setIsCheckedS] = useState(false);
    const [information_our_rugs, setInformationRugs] = useState('')
    const [isCheckedR, setIsCheckedR] = useState(false);
    const [error,setError]=useState(false)
    const [success,setSuccess]=useState(false)
    //destructure token and user from localStorage
    const {token} =isAuthenticated()
    const loadUpdateInformation = informationId => {
        informationsDetails(informationId).then(data => {
                setInformationName(data.information_name)
                setInformationSlug(data.information_slug)
                setInformationDescription(data.information_description)
                setInformationImage(data.information_image)
                setInformationServices(data.information_services)
                if(data.information_services.toString()==='1'){
                    setIsCheckedS(!isCheckedS);
                } else {
                    setIsCheckedS(isCheckedS);
                }
                setInformationRugs(data.information_our_rugs)
                if(data.information_our_rugs.toString()==='1'){
                    setIsCheckedR(!isCheckedR);
                } else {
                    setIsCheckedR(isCheckedR);
                }
        })
    }

    useEffect(() => {
        const informationId = params.informationId
        loadUpdateInformation(informationId)
    }, [params.informationId])

   const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('information_image', file)
        uploadinformationimg(token,`${informationId}`,formData)
            .then(data=>{
                if(data.error){
                    setError(data.error)
                }
                else{
                    setError('')
                    setSuccess(true)
                    setInformationImage(data)
                }
        })
  }
   
    const submitHandler = (e) => {
        e.preventDefault()
        setError('')
        setSuccess(false)
        //make request to add category

        updateInformation(token,`${informationId}`,{
            information_name:information_name,
            information_slug:information_slug,
            information_description:information_description,
            information_image:information_image,
            information_services:information_services,
            information_our_rugs:information_our_rugs
        })
        .then(data=>{
            if(data.error){
                setError(data.error)
            }
            else{
                setError('')
                setSuccess(true)
                setInformationName(information_name)
                setInformationSlug(information_slug)
                setInformationDescription(information_description)
                setInformationImage(information_image)
                setInformationServices(information_services)
                setInformationRugs(information_our_rugs)
            }
        })
      }
    const RemoveuploadFileHandler = (id) => {
        if (window.confirm('Are you sure')) {
            setError('')
            setSuccess(false)
            //make request to add category
            deleteuploadFileInformation(token,`${id}`)
            .then(data=>{
                if(data.error){
                    setError(data.error)
                }
                else{
                    setError('')
                    setSuccess(true)
                    setInformationImage(data)
                }
            })
        }
    }
    const ondescription = (value) => {
        setInformationDescription(value)
    }

    const handleOnChangeS = (e) => {
        if(isCheckedS===false){
            setInformationServices(1)
        } else {
            setInformationServices(0)
        }
        setIsCheckedS(!isCheckedS);
    }

    const handleOnChangeR = (e) => {
       
        if(isCheckedR===false){
            setInformationRugs(1)
        } else {
            setInformationRugs(0)
        }
        setIsCheckedR(!isCheckedR);
    }
      // to show error msg
    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       Success: You have modified Information!
    </div>
    )
    return (
        <>
        <div className="container dashboard-wrapper">
            <div className="row">
                <div className="col-md-3">
                    <AdminSidebar/>
                </div>
                <div className="col-md-6 adminpage-container">
                    <h3>Edit Information</h3>
                    <form className="shadow-lg p-3">
                        {showError()}
                        {showSuccess()}
                        <div className="mb-3">
                            <label htmlFor="informationname">Information Name</label>
                            <input type="text" id="informationname" className="form-control"
                            value={information_name} onChange={(e) => setInformationName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="informationslug">Information Slug</label>
                            <input type="text" id="informationslug" className="form-control"
                            value={information_slug} onChange={(e) => setInformationSlug(e.target.value.toLowerCase().trim().split(' ').join('-'))}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description">Information Description</label>
                            <EditorToolbar toolbarId={'infodescedit'} />
                            <ReactQuill
                              theme="snow"
                              value={information_description}
                              onChange={ondescription}
                              placeholder={"Write something awesome..."}
                              modules={modules('infodescedit')}
                              formats={formats}
                            /> 
                           
                        </div>
                        <div className="mb-3">
                            <label htmlFor="image">Information Image</label><br />
                             {information_image.length>0 ? (
                            <>
                            <img src={`${API}/${information_image}`} alt={information_name} className="img-fluid" width="130" />
                            <button onClick={() => RemoveuploadFileHandler(informationId)}>Remove Image</button>
                            </>
                            ) : (<img src={`${API}/public/uploads/default-placeholder.png`} alt={information_name} className="img-fluid" width="130" />)}
                            <input type="file" id="image" className="form-control" accept="image/*"
                            onChange={uploadFileHandler}
                             />
                        </div>
                         <div className="mb-3">
                            <input type="checkbox" id="information_services" value={information_services} checked={isCheckedS} onChange={handleOnChangeS}
                            /> 
                            Services
                          </div>
                          <div className="mb-3">
                            <input type="checkbox" id="information_our_rugs" value={information_our_rugs} checked={isCheckedR} onChange={handleOnChangeR}
                            /> 
                            Our Rugs
                          </div>
                       <div className="mb-3">
                           <button className="btn btn-primary" onClick={submitHandler}>Edit Information</button>
                       </div>

                    </form>
                </div>
            </div>
        </div>
            
        </>
    )
}

export default EditInformation
