import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { API } from "../../config";
import { isAuthenticated } from '../auth'
import AdminSidebar from './AdminSidebar'
import Pagination from "../../components/Pagination";
import { deleteUser } from './apiIndex'

const Users = () => {
    const [userlist, setUserList] = useState([]);
    const [error, setError] = useState(false);
    const [success,setSuccess]=useState(false)

    const params = useParams()
    const pageNumber = params.pageNumber || 1;
    const [page, setPage] = useState(pageNumber);
    const [pages, setPages] = useState(1);
    const [pageSize, setPageSize] = useState('');
    const [total, setTotal] = useState('');

    const {token} =isAuthenticated()

    const start = (() => { 
        if(page>1)
            { 
                return (pageSize * (page -1))
            } 
            return 1; 
    })();

    const end = (() => { 
        if(page>1){ 
            if(page===pages){
                return total
            }
            return (pageSize + start)
        } 
        return pageSize; 
    })();

    const loadUserList = async() => {
                    const res = await fetch(`${API}/api/alluserlist?page=${page}`,{
                                    method:'GET',
                                    headers:{
                                        Accept:'application/json',
                                        'Content-Type':'application/json',
                                        Authorization:`Bearer ${token}`
                                    },
                                });
                    if(res.ok) {
                            const { data, pages: totalPages, total, pageSize } = await res.json();
                            if (data.error) {
                                console.log(data.error)
                            } else {
                                setPageSize(pageSize);
                                setPages(totalPages);
                                setTotal(total);
                                setUserList(data);
                            }
                        } else {
                                const res = await fetch(`${API}/api/alluserlist?page=${page-1}`,{
                                    method:'GET',
                                    headers:{
                                        Accept:'application/json',
                                        'Content-Type':'application/json',
                                        Authorization:`Bearer ${token}`
                                    },
                                });
                                 if(res.ok) {
                                    const { data, pages: totalPages, total, pageSize } = await res.json();
                                    if (data.error) {
                                        console.log(data.error)
                                    } else {
                                        setPageSize(pageSize);
                                        setPage(page-1);
                                        setPages(totalPages);
                                        setTotal(total);
                                        setUserList(data);
                                    }
                                } else {
                                    const res = await fetch(`${API}/api/userlist`,{
                                        method:'GET',
                                        headers:{
                                            Accept:'application/json',
                                            'Content-Type':'application/json',
                                            Authorization:`Bearer ${token}`
                                        },
                                    });
                                    if(res.ok) {
                                         const { data } = await res.json();
                                         setUserList(data);
                                    } else {
                                        setError('')
                                        setSuccess(false)
                                    }
                                }
                        }
    };
    

    const deleteHandler = (id) => {
        if (window.confirm('Are you sure')) {
            setError('')
            setSuccess(false)
            //make request to delete user
            deleteUser(token,`${id}`)
            .then(data=>{
                if(data.error){
                    setError(data.error)
                }
                else{
                    loadUserList()
                    setError('')
                    setSuccess(true)
                }
            })
        }
      }

    useEffect(() => {
            const {token} =isAuthenticated()
            const loadUserList = async() => {
            const res = await fetch(`${API}/api/alluserlist?page=${page}`,{
                            method:'GET',
                            headers:{
                                Accept:'application/json',
                                'Content-Type':'application/json',
                                Authorization:`Bearer ${token}`
                            },
                        });
            if(res.ok) {
                    const { data, pages: totalPages, total, pageSize } = await res.json();
                    if (data.error) {
                        console.log(data.error)
                    } else {
                        setPageSize(pageSize);
                        setPages(totalPages);
                        setTotal(total);
                        setUserList(data);
                    }
                } else {
                        const res = await fetch(`${API}/api/alluserlist?page=${page-1}`,{
                            method:'GET',
                            headers:{
                                Accept:'application/json',
                                'Content-Type':'application/json',
                                Authorization:`Bearer ${token}`
                            },
                        });
                         if(res.ok) {
                            const { data, pages: totalPages, total, pageSize } = await res.json();
                            if (data.error) {
                                console.log(data.error)
                            } else {
                                setPageSize(pageSize);
                                setPage(page-1);
                                setPages(totalPages);
                                setTotal(total);
                                setUserList(data);
                            }
                        } else {
                            const res = await fetch(`${API}/api/userlist`,{
                                method:'GET',
                                headers:{
                                    Accept:'application/json',
                                    'Content-Type':'application/json',
                                    Authorization:`Bearer ${token}`
                                },
                            });
                            if(res.ok) {
                                 const { data } = await res.json();
                                 setUserList(data);
                            } else {
                                setError('')
                                setSuccess(false)
                            }
                        }
                }
        };
        loadUserList()
    }, [page])
    // to show error msg
    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       Success: You have modified users!
    </div>
    )
    return (
        <>
            <div className="container-fluid"  style={{margin:'25px'}}>
                <div className="row">
                    <div className="col-md-3">
                        <AdminSidebar />
                    </div>
                    <div className="col-md-6 adminpage-container">
                    	<div className="headerwrap">
                        <h6 className="text-center text-muted m-3 pull-right">
                            Showing {start} to {end} of {total} ({pages} Pages)
                        </h6>
                        {showError()}
                        {showSuccess()}
                        </div>
                        <hr />
                        <div className="table-responsive">
                        <table className="table table-bordered table-secondary">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {userlist && userlist.length>0 ? (
                             <tbody>
                                {userlist.map((data, i) => (
                                    <tr key={i}>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>
                                            <Link to={`/admin/edituser/${data._id}`} className="btn btn-warning">
                                                Update
                                            </Link>
                                            <Link to="/admin/allusers" className="btn btn-danger" onClick={() => deleteHandler(data._id)}>
                                                Delete
                                            </Link>

                                        </td>

                                    </tr>
                                 ))}
                            </tbody>
                            ) : (
                               <tbody>
                                    <tr><td colSpan={3} style={{textAlign:"center"}}>No results!</td></tr>
                                 </tbody>
                            )}
                        </table>
                        </div>
                        <Pagination page={page} pages={pages} changePage={setPage} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Users
