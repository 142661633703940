import { API } from "../../config";
import axios from 'axios'

//To fetch all media
export const allmedias=()=>{
    return fetch(`${API}/api/medialist`,{
        method:'GET'    
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//To fetch all media
export const allgallery=()=>{
    return fetch(`${API}/api/gallerylist`,{
        method:'GET'    
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Add Media
export const addmedia=(token,media)=>{
    return fetch(`${API}/api/postmedia`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },
        body:media
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Delete Media
export const deleteMedia=(token,mediaId)=>{
    return fetch(`${API}/api/deletemedia/${mediaId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Media details
export const mediasDetails=(mediaId)=>{
    return fetch(`${API}/api/mediadetails/${mediaId}`,{
        method:'GET'
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}

//Media update
export const updateMedia=(token,mediaId,media)=>{
    return fetch(`${API}/api/updatemedia/${mediaId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
        body:JSON.stringify(media)
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Upload Image Media
export const uploadmediaimg=async(token,mediaId,media)=>{
    const config = {
            method:'POST',
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }  
    }
    const { data } = await axios.post(`${API}/api/uploadmediaimg/${mediaId}`, media, config)
    const data_image={media_image:data}
    fetch(`${API}/api/updateuploadmediaimg/${mediaId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
        body:JSON.stringify(data_image)
    })
    return data;

}

//Delete Upload Image Media
export const deleteuploadFileMedia=(token,MediaId)=>{
    return fetch(`${API}/api/deleteuploadmediaimg/${MediaId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}


//Get Information
export const allinformations=()=>{
    return fetch(`${API}/api/informationlist`,{
        method:'GET'    
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Add Information
export const addinformation=(token,information)=>{
    return fetch(`${API}/api/postinformation`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },
        body:information
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Delete Information
export const deleteInformation=(token,informationId)=>{
    return fetch(`${API}/api/deleteinformation/${informationId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Information details
export const informationsDetails=(informationId)=>{
    return fetch(`${API}/api/informationdetails/${informationId}`,{
        method:'GET'
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}

//Information update
export const updateInformation=(token,informationId,information)=>{
    return fetch(`${API}/api/updateinformation/${informationId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
        body:JSON.stringify(information)
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Upload Image Information
export const uploadinformationimg=async(token,informationId,information)=>{
    const config = {
            method:'POST',
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }  
    }
    const { data } = await axios.post(`${API}/api/uploadinformationimg/${informationId}`, information, config)
    const data_image={information_image:data}
    fetch(`${API}/api/updateuploadinformationimg/${informationId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
        body:JSON.stringify(data_image)
    })
    return data;

}


//Delete Upload Image Information
export const deleteuploadFileInformation=(token,informationId)=>{
    return fetch(`${API}/api/deleteuploadinformationimg/${informationId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Get Setting
export const allsettings=()=>{
    return fetch(`${API}/api/settinglist`,{
        method:'GET'    
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Add Setting
export const addsetting=(token,setting)=>{
    return fetch(`${API}/api/postsetting`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },
        body:setting
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Delete Setting
export const deleteSetting=(token,settingId)=>{
    return fetch(`${API}/api/deletesetting/${settingId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Setting details
export const settingsDetails=(settingId)=>{
    return fetch(`${API}/api/settingdetails/${settingId}`,{
        method:'GET'
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}

//Setting update
export const updateSetting=(token,settingId,setting)=>{
    return fetch(`${API}/api/updatesetting/${settingId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
        body:JSON.stringify(setting)
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//Upload Image Setting
export const uploadsettingimg=async(token,settingId,setting)=>{
    const config = {
            method:'POST',
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }  
    }
    const { data } = await axios.post(`${API}/api/uploadsettingimg/${settingId}`, setting, config)
    const data_image={setting_image:data}
    fetch(`${API}/api/updateuploadsettingimg/${settingId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
        body:JSON.stringify(data_image)
    })
    return data;

}


//Delete Upload Image Setting
export const deleteuploadFileSetting=(token,settingId)=>{
    return fetch(`${API}/api/deleteuploadsettingimg/${settingId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//to fetch all Users
export const allusers=(token)=>{
    return fetch(`${API}/api/userlists`,{
        method:'GET',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },    
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//user detail
export const userDetail=(token,userId)=>{
    return fetch(`${API}/api/userdetail/${userId}`,{
        method:'GET',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}

//user details
export const userDetails=(token,userId)=>{
    return fetch(`${API}/api/userdetails/${userId}`,{
        method:'GET',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>console.log(err))
}


export const updateUser=(token,userId,user)=>{
    return fetch(`${API}/api/updateuser/${userId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
        body:JSON.stringify(user)
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}

//delete User
export const deleteUser=(token,userId)=>{
    return fetch(`${API}/api/deleteuser/${userId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${token}`
        },
    })
    .then(response=>{
        return response.json()
    })
    .catch(err=>{
        console.log(err)
    })
}
