import React, { useState } from 'react'
import Meta from '../../components/Meta'

import { Link, useNavigate, useLocation,Navigate } from 'react-router-dom'
import { login,authenticate,isAuthenticated } from './index'


const Login = () => {
    const navigate = useNavigate()
    const pathname  = useLocation()
    const{user}=isAuthenticated()

    const [values, setValues] = useState({
        email: '',
        password: '',
        error: '',
        redirectToPage: false
    })

    const { email, password, error, redirectToPage } = values

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        setValues({ ...values, error: false })
        login({ email, password })
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error })
                }
                else {
                    authenticate(data,()=>{
                        setValues({ ...values, redirectToPage: true })
                    })          
                }
            })
    }
    // to show error msg
    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    )

    /// to redirect user
    const redirectUser=()=>{
        
        if(redirectToPage){
            if(user && user.role===1){
                return navigate('/admin/dashboard')
            }
        }
        
    }
    return (
        <>
            <Meta title="Admin Login" />
            <div className="admin-wrapper">
                <div className="logo">
                    <img src={window.location.origin + '/images/favicon/apple-touch-icon.png'} alt="Main Logo"/>
                </div>
                <div className="text-center mt-4 name">
                    Siddhartha Carpet House
                </div>
                <form className="p-3 mt-3">
                    {showError()}
                    {redirectUser()}
                    <div className="form-field d-flex align-items-center">
                        <span className="fa fa-user"></span>
                        <input type="email" name="email" id="email" placeholder="example@gmail.com" className="form-control" onChange={handleChange('email')} value={email} />
                    </div>
                    <div className="form-field d-flex align-items-center">
                        <span className="fa fa-key"></span>
                        <input type="password" name="pass" id="password" placeholder="***********" className="form-control" onChange={handleChange('password')} value={password} />
                    </div>
                    <button className="btn mt-3" onClick={handleSubmit}>Login</button>
                </form>
                <div className="text-center fs-6">
                    <Link to="/forgetpassword" className="text-decoration-none text-secondary">Forget Password?</Link>
                </div>
            </div>
        </>
    )
}

export default Login
