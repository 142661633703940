import React,{useState, useEffect} from 'react'
import AdminSidebar from './AdminSidebar'
import { isAuthenticated } from '../auth'
import { userDetails, updateUser } from './apiIndex'
import { useParams } from 'react-router-dom'

const EditUser = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [error,setError]=useState(false)
    const [success,setSuccess]=useState(false)
    const params = useParams()
    const userId = params.userId
    //destructure token and user from localStorage
    const {token} =isAuthenticated()
    
    useEffect(() => {
        const userId = params.userId
        const {token} =isAuthenticated()
        const loadUpdateUser = userId => {
            userDetails(token,userId).then(data => {
                    setName(data.name)
                    setEmail(data.email)
            })
        }
        loadUpdateUser(userId)
    }, [params.userId])

    const submitHandler = (e) => {
        e.preventDefault()
        setError('')
        setSuccess(false)
        //make request to add user
        updateUser(token,`${userId}`,
        {
            name:name,
            email:email
        })
        .then(data=>{
            if(data.error){
                setError(data.error)
            }
            else{
                setError('')
                setSuccess(true)
                setName(name)
                setEmail(email)
            }
        })
      }
    
// to show error msg
    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       Success: You have modified User!
    </div>
    )

    return (
        <>
            <div className="container dashboard-wrapper">
                <div className="row">
                    <div className="col-md-3">
                        <AdminSidebar />
                    </div>
                    <div className="col-md-6 adminpage-container">
                        <h3>Edit User</h3>
                        <form className="shadow-lg p-3">
                            {showError()}
                            {showSuccess()}
                            <div className="mb-3">
                                <label htmlFor='user'>Name</label>
                                <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor='user'>Email</label>
                                <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control"/>
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-primary" onClick={submitHandler} >Edit User</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditUser
