import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { listSettings } from '../components/uiApi'
import {API} from '../config'
import ReactHtmlParser from 'react-html-parser';
const Footer = () => {
    const [error, setError] = useState(false)
    const [settinglist, setSettingList] = useState([])
    const loadSettingList = () => {
        listSettings().then(data => {
                if (data.error) {
                    setError(data.error)
                }
                else {
                    setSettingList(data)
                }
        })
    }
       
    useEffect(() => {
        loadSettingList()
    }, [])
   
    return (
        <>
        {error}
        <footer className="footer-area">
            <div className="shape wow fadeInRight" data-wow-duration="3000ms"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="single-footer-widget marbtm50">
                                {settinglist && settinglist.length>0 ? (
                                <>
                                    { settinglist[1].setting_slug==='footer-logo' && settinglist[1].setting_image!=='' ? (
                                        <>
                                        <div className="footer-logo">
                                        <Link to="/">
                                            <img src={`${API}/${settinglist[1].setting_image}`} alt="Footer Logo"/>
                                        </Link>
                                        </div>
                                        </>
                                    ) : (
                                        <div className="footer-logo">
                                           <Link to="/"><img src={ window.location.origin +  '/images/resources/footer-logo.png'} alt="Footer Logo"/></Link>
                                        </div>
                                    )}
                                </>
                                ) : ('')}
                                {settinglist && settinglist.length>0 ? (
                                <>
                                    { settinglist[2].setting_slug==='footer-info-text' && settinglist[2].setting_image!=='' ? (
                                        <>
                                        <div className="company-info">
                                            <div className="text-box">
                                                { ReactHtmlParser(settinglist[2].setting_description) }
                                            </div>    
                                        </div>
                                        </>
                                    ) : (
                                        <div className="company-info">
                                            <div className="text-box">
                                                <p>Siddhartha Carpet House produces fine export quality hand knotted rugs and we supply export quality of rugs to the world.</p>
                                            </div>    
                                        </div>
                                    )}
                                </>
                                ) : ('')}
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="single-footer-widget marleft70 marbtm50">
                                <div className="title">
                                    <h3>Servies</h3>
                                </div>
                                    <ul className="explore-links">
                                        <li><Link to="/page/about-us">About</Link></li>
                                        <li><Link to="/contact-us">Contact</Link></li>
                                        <li><Link to="/page/quality-control">Quality Control</Link></li>
                                        <li><Link to="/page/service-policy">Service Policy</Link></li>
                                        <li><Link to="/page/rug-care">Rug Care</Link></li>
                                        <li><Link to="/page/price-list">Price List</Link></li>
                                </ul>   
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="single-footer-widget marleft30">
                                <div className="title">
                                    <h3>Our Rugs</h3>
                                </div>
                                <ul className="legal-links">
                                    <li><Link to="/page/himalayan-tibetan-wool-rugs">Himalayan wool rugs</Link></li>
                                    <li><Link to="/page/green-plant-fiber-nettle-allo-rugs">Green plant fiber - Nettle (allo) rugs</Link></li>
                                    <li><Link to="/page/green-plant-fiber-hemp-rugs">Green plant fiber - Hemp rugs</Link></li>
                                    <li><Link to="/page/green-plant-fiber-banana-silk-rugs">Green plant fiber - Banana silk rugs</Link></li>
                                    <li><Link to="/page/natural-chinese-silk-rugs">Natural chinese silk rugs</Link></li>
                                </ul>  
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="single-footer-widget marleft40 pdtop50">
                                <div className="title">
                                    <h3>You have any Question?</h3>
                                </div>
                                <ul className="contact-info">
                                    <li>
                                        <span>Email</span>
                                        {settinglist && settinglist.length>0 ? (
                                            <>
                                                { settinglist[3].setting_slug==='email-address' ? (
                                                    <>
                                                    <h3 style={{fontSize:"12px"}}>{ settinglist[3].setting_text }</h3>
                                                    </>
                                                ) : (
                                                    <h3 style={{fontSize:"12px"}}>info@siddharthacarpethouse.com.np, sidharthacarpet@yahoo.com</h3>
                                                )}
                                            </>
                                        ) : ('')}
                                    </li>
                                    <li>
                                        <div className="text">
                                        {settinglist && settinglist.length>0 ? (
                                            <>
                                                { settinglist[4].setting_slug==='contact-address'? (
                                                    <>
                                                        <p>{ settinglist[4].setting_text }</p>
                                                    </>
                                                ) : (
                                                        <p>Swoyambhu, Kathmandu, Nepal</p>
                                                )}
                                            </>
                                        ) : ('')}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
            </div>
        </footer>
        <section className="footer-bottom-area">
        <div className="container">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="inner text-center clearfix">
                        <div className="copyright-text">
                            <p>© Copyright by Siddhartha Carpet House</p>
                        </div>
                    </div>   
                </div>
            </div>
        </div>    
    </section>
        </>
    )
}

export default Footer
