import React,{useState,useEffect} from 'react'
import AdminSidebar from './AdminSidebar'
import {isAuthenticated} from '../auth'
import { addinformation } from './apiIndex'

import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from '../../components/editor/EditorToolbar'
import 'react-quill/dist/quill.snow.css'
import '../../components/editor/TextEditor.css'

const AddInformation = () => {
    
    const {token} =isAuthenticated()
    const [isCheckedS, setIsCheckedS] = useState(false);
    const [isCheckedR, setIsCheckedR] = useState(false);
  
    const [descvalue,setdescValue]=useState({
        information_description:'',
    })
    const [services_value,setservices_value]=useState({
        information_services:0,
    })
    const [our_rugs_value,setour_rugs_value]=useState({
        information_our_rugs:0,
    })
    const [values,setValues]=useState({
        information_name:'',
        information_slug:'',
        information_image:'',
        error:'',
        success:false,
        formData:''
    })

    const {information_description} = descvalue
    const {information_services} = services_value
    const {information_our_rugs} = our_rugs_value
    const {information_name,information_slug,error,success,formData} = values

    //load set form data
     const init=()=>{
         setValues({...values,formData:new FormData})
     }
     //to send form data
     useEffect(()=>{
         init()
     },[])

    const ondescription = (value) => {
        const name='information_description'
        formData.set(name,value)
        setdescValue({...descvalue,[name]:value})
    }

    const handleOnChangeS = (e) => {
        if(isCheckedS===false){
            const name='information_services'
            formData.set(name,1)
            setservices_value({...services_value,[name]:1})
        } else {
            const name='information_services'
            formData.set(name,0)
            setservices_value({...services_value,[name]:0})
        }
        setIsCheckedS(!isCheckedS);
    }

    const handleOnChangeR = (e) => {

        if(isCheckedR===false){
            const name='information_our_rugs'
            formData.set(name,1)
            setour_rugs_value({...our_rugs_value,[name]:1})

        } else {
            const name='information_our_rugs'
            formData.set(name,0)
            setour_rugs_value({...our_rugs_value,[name]:0})
        }
        setIsCheckedR(!isCheckedR);
    }

    const handleChange=name=>event=>{
        switch (name) {
            case 'information_slug':
                formData.set(name,event.target.value.toLowerCase().trim().split(' ').join('-'))
                setValues({...values,[name]:event.target.value.toLowerCase().trim().split(' ').join('-')})
                break;
            case 'information_image':
                formData.set(name,event.target.files[0])
                setValues({...values,[name]:event.target.files[0]})
                break;
            default:
                formData.set(name,event.target.value)
                setValues({...values,[name]:event.target.value})
                break;
          }
     }


     const handleSubmit=e=>{
         e.preventDefault()
         setValues({...values, error:''})
         addinformation(token,formData)
         .then(data => {
             if(data.error){
                 setValues({...values,error:data.error})
             }
             else{
                 setdescValue({...descvalue,information_description:''})
                 setservices_value({...services_value,information_services:0})
                 setour_rugs_value({...our_rugs_value,information_our_rugs:0})
                 setValues({...values,information_name:'',information_slug:'',information_image:'',success:true,error:''})
                 setIsCheckedS(isCheckedS);
                 setIsCheckedR(isCheckedR);
             }
         })
     }

    

      // to show error msg
    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       New information added
    </div>
    )
    return (
        <>
        <div className="container dashboard-wrapper">
            <div className="row">
                <div className="col-md-3">
                    <AdminSidebar/>
                </div>
                <div className="col-md-6 adminpage-container">
                    <h3>Add Information</h3>
                    <form className="shadow-lg p-3">
                        {showError()}
                        {showSuccess()}
                        <div className="mb-3">
                            <label htmlFor="informationname">Name</label>
                            <input type="text" id="informationname" className="form-control"
                            onChange={handleChange('information_name')} value={information_name}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="informationslug">Slug</label>
                            <input type="text" id="informationslug" className="form-control"
                            onChange={handleChange('information_slug')} value={information_slug}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description">Description</label>
                            <EditorToolbar toolbarId={'infodesc'} />
                            <ReactQuill
                              theme="snow"
                              value={information_description || ''}
                              onChange={ondescription}
                              placeholder={"Write something awesome..."}
                              modules={modules('infodesc')}
                              formats={formats}
                            /> 
                        </div>

                        <div className="mb-3">
                            <label htmlFor="image">Image</label>
                            <input type="file" id="image" className="form-control" accept="image/*"
                            onChange={handleChange('information_image')} 
                             />
                        </div>

                        
                        <div className="mb-3">
                            <input type="checkbox" id="information_services" value={information_services} checked={isCheckedS} onChange={handleOnChangeS}
                            /> 
                            Services
                          </div>
                        <div className="mb-3">
                            <input type="checkbox" id="information_our_rugs" value={information_our_rugs} checked={isCheckedR} onChange={handleOnChangeR}
                            /> 
                            Our Rugs
                          </div>

                       <div className="mb-3">
                           <button className="btn btn-primary" onClick={handleSubmit}>Add Information</button>
                       </div>

                    </form>
                </div>
            </div>
        </div>
            
        </>
    )
}

export default AddInformation
