import { API } from "../../config";
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import AdminSidebar from './AdminSidebar'
import {isAuthenticated} from '../auth'
import { settingsDetails, updateSetting, uploadsettingimg, deleteuploadFileSetting } from './apiIndex'

import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from '../../components/editor/EditorToolbar'
import 'react-quill/dist/quill.snow.css'
import '../../components/editor/TextEditor.css'
const EditSetting = () => {
    const params = useParams()
    const settingId = params.settingId
    const [setting_name, setSettingName] = useState('')
    const [setting_slug, setSettingSlug] = useState('')
    const [setting_type, setSettingType] = useState('')
    const [setting_text, setSettingText] = useState('')
    const [setting_description, setSettingDescription] = useState('')
    const [setting_image, setSettingImage] = useState('')
    const [setting_permanent, setSettingBottomMenu] = useState('')
    const [isChecked, setIsChecked] = useState(false);
    const [error,setError]=useState(false)
    const [success,setSuccess]=useState(false)
    //destructure token and user from localStorage
    const {token} =isAuthenticated()
    const loadUpdateSetting = settingId => {
        settingsDetails(settingId).then(data => {
                setSettingName(data.setting_name)
                setSettingSlug(data.setting_slug)
                setSettingType(data.setting_type)
                setSettingText(data.setting_text)
                setSettingDescription(data.setting_description)
                setSettingImage(data.setting_image)
                setSettingBottomMenu(data.setting_permanent)
                if(data.setting_permanent.toString()==='1'){
                    setIsChecked(!isChecked);
                } else {
                    setIsChecked(isChecked);
                }
        })
    }

    useEffect(() => {
        const settingId = params.settingId
        loadUpdateSetting(settingId)
    }, [params.settingId])

   const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('setting_image', file)
        uploadsettingimg(token,`${settingId}`,formData)
            .then(data=>{
                if(data.error){
                    setError(data.error)
                }
                else{
                    setError('')
                    setSuccess(true)
                    setSettingImage(data)
                }
        })
  }
   
    const submitHandler = (e) => {
        e.preventDefault()
        setError('')
        setSuccess(false)
        //make request to add category

        updateSetting(token,`${settingId}`,{
            setting_name:setting_name,
            setting_slug:setting_slug,
            setting_type:setting_type,
            setting_text:setting_text,
            setting_description:setting_description,
            setting_image:setting_image,
            setting_permanent:setting_permanent
        })
        .then(data=>{
            if(data.error){
                setError(data.error)
            }
            else{
                setError('')
                setSuccess(true)
                setSettingName(setting_name)
                setSettingSlug(setting_slug)
                setSettingType(setting_type)
                setSettingText(setting_text)
                setSettingDescription(setting_description)
                setSettingImage(setting_image)
                setSettingBottomMenu(setting_permanent)
            }
        })
      }
    const RemoveuploadFileHandler = (id) => {
        if (window.confirm('Are you sure')) {
            setError('')
            setSuccess(false)
            //make request to add category
            deleteuploadFileSetting(token,`${id}`)
            .then(data=>{
                if(data.error){
                    setError(data.error)
                }
                else{
                    setError('')
                    setSuccess(true)
                    setSettingImage(data)
                }
            })
        }
    }
    const ondescription = (value) => {
        setSettingDescription(value)
    }

    const handleOnChange = (e) => {
        if(isChecked===false){
            setSettingBottomMenu(1)
        } else {
            setSettingBottomMenu(0)
        }
        setIsChecked(!isChecked);
    }
    
      // to show error msg
    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       Success: You have modified Setting!
    </div>
    )
    return (
        <>
        <div className="container dashboard-wrapper">
            <div className="row">
                <div className="col-md-3">
                    <AdminSidebar/>
                </div>
                <div className="col-md-6 adminpage-container">
                    <h3>Edit Setting</h3>
                    <form className="shadow-lg p-3">
                        {showError()}
                        {showSuccess()}
                        <div className="mb-3">
                            <label htmlFor="settingname">Setting Name</label>
                            <input type="text" id="settingname" className="form-control"
                            value={setting_name} onChange={(e) => setSettingName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="settingslug">Setting Slug</label>
                            <input type="text" id="settingslug" className="form-control"
                            value={setting_slug} onChange={(e) => setSettingSlug(e.target.value.toLowerCase().trim().split(' ').join('-'))}
                            />
                        </div>
                          <input type="hidden" name="setting_type" value={setting_type} />
                        { setting_type==="text" ? (
                        <>
                        <div className="mb-3">
                            <label htmlFor="settingtext">Setting Value</label>
                            <input type="text" id="settingtext" className="form-control"
                            value={setting_text} onChange={(e) => setSettingText(e.target.value)}
                            />
                        </div>
                        </>
                        ) : ('')}
                        { setting_type==="textarea" ? (
                        <>
                        <div className="mb-3">
                            <label htmlFor="description">Setting Description</label>
                            <EditorToolbar toolbarId={'infodescedit'} />
                            <ReactQuill
                              theme="snow"
                              value={setting_description}
                              onChange={ondescription}
                              placeholder={"Write something awesome..."}
                              modules={modules('infodescedit')}
                              formats={formats}
                            /> 
                        </div>
                        </>
                        ) : ('')}
                        { setting_type==="image" ? (
                        <>
                        <div className="mb-3">
                            <label htmlFor="image">Setting Image</label><br />
                             {setting_image.length>0 ? (
                            <>
                            <img src={`${API}/${setting_image}`} alt={setting_name} className="img-fluid" width="130" />
                            <button onClick={() => RemoveuploadFileHandler(settingId)}>Remove Image</button>
                            </>
                            ) : (<img src={`${API}/public/uploads/default-placeholder.png`} alt={setting_name} className="img-fluid" width="130" />)}
                            <input type="file" id="image" className="form-control" accept="image/*"
                            onChange={uploadFileHandler}
                             />
                        </div>
                        </>
                        ) : ('')}
                         <div className="mb-3">
                            <input type="checkbox" id="setting_permanent" value={setting_permanent} checked={isChecked} onChange={handleOnChange}
                            /> 
                            Parmanent
                          </div>
                       <div className="mb-3">
                           <button className="btn btn-primary" onClick={submitHandler}>Edit Setting</button>
                       </div>

                    </form>
                </div>
            </div>
        </div>
            
        </>
    )
}

export default EditSetting
