import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {API} from '../config'
import { listSettings } from './uiApi'
const Navbar = () => {
    const [error, setError] = useState(false)

    let pathname = window.location.pathname;

    const [settinglist, setSettingList] = useState([])
    const loadSettingList = () => {
        listSettings().then(data => {
                if (data.error) {
                    setError(data.error)
                }
                else {
                    setSettingList(data)
                }
        })
    }

    useEffect(() => {
        loadSettingList()
    }, [])
    
    return (
        <>
        {error}
        <header className="main-header stricky">
            <div className="main-box">
                <div className="inner-container clearfix">
                   {settinglist && settinglist.length>0 ? (
                    <>
                        { settinglist[0].setting_slug==='main-logo' && settinglist[0].setting_image!=='' ? (
                            <>
                            <div className="logo-box">
                            <Link to="/">
                                <img src={`${API}/${settinglist[0].setting_image}`} alt="Main Logo"/>
                            </Link>
                            </div>
                            </>
                        ) : (
                            <div className="logo-box">
                                <Link to="/">
                                    <img src={window.location.origin + '/images/resources/logo.png'} alt="Main Logo"/>
                                </Link>
                            </div>
                        )}
                    </>
                    ) : ('')}
                    <div className="nav-outer clearfix">
                        <nav className="main-menu clearfix">
                            <div className="navbar-header clearfix">    
                                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                            <div className="navbar-collapse collapse clearfix">
                                <ul className="mobile-navigation clearfix">
                                    <li className={`${pathname=== "/"  ? 'current' : ''}`}><Link to="/">Home</Link></li>
                                    <li className={`${pathname.match('/about-us') ? 'current' : ''}`}><Link to="/page/about-us">About</Link></li>
                                    <li className={`${pathname.match('/gallery') ? 'current' : ''}`}><Link to="/gallery">Gallery</Link></li>
                                    <li className="dropdown" className={`${pathname.match('/quality-control') || pathname.match('/service-policy') || pathname.match('/rug-care') ? 'current' : ''}`}>
                                    <Link to="#" className="dropdown-toggle" id="navbarDropdown" role="button"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Services
                                    </Link>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><Link to="/page/quality-control">Quality Control</Link></li>
                                            <li><Link to="/page/service-policy">Service Policy</Link></li>
                                            <li><Link to="/page/rug-care">Rug Care</Link></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown" className={`${pathname.match('/himalayan-tibetan-wool-rugs') || pathname.match('/green-plant-fiber-nettle-allo-rugs') || pathname.match('/green-plant-fiber-hemp-rugs') || pathname.match('/green-plant-fiber-banana-silk-rugs') || pathname.match('/natural-chinese-silk-rugs') ? 'current' : ''}`}>
                                    <Link to="#" className="dropdown-toggle" id="navbarDropdown" role="button"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Our Rugs
                                    </Link>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                             <li><Link to="/page/himalayan-tibetan-wool-rugs">Himalayan wool rugs</Link></li>
                                            <li><Link to="/page/green-plant-fiber-nettle-allo-rugs">Green plant fiber - Nettle (allo) rugs</Link></li>
                                            <li><Link to="/page/green-plant-fiber-hemp-rugs">Green plant fiber - Hemp rugs</Link></li>
                                            <li><Link to="/page/green-plant-fiber-banana-silk-rugs">Green plant fiber - Banana silk rugs</Link></li>
                                            <li><Link to="/page/natural-chinese-silk-rugs">Natural chinese silk rugs</Link></li>
                                        </ul>
                                    </li>
                                    <li className={`${pathname.match('/price-list') ? 'current' : ''}`}><Link to="/page/price-list">Price List</Link></li>
                                    <li className={`${pathname.match('/contact-us') ? 'current' : ''}`}><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                                                              
                                <ul className="navigation clearfix">
                                    <li className={`${pathname=== "/" ? 'current' : ''}`}><Link to="/">Home</Link></li>
                                    <li className={`${pathname.match('/about-us') ? 'current' : ''}`}><Link to="/page/about-us">About</Link></li>
                                    <li className={`${pathname.match('/gallery') ? 'current' : ''}`}><Link to="/gallery">Gallery</Link></li>
                                    <li className="dropdown" className={`${pathname.match('/quality-control') || pathname.match('/service-policy') || pathname.match('/rug-care') ? 'current' : ''}`}><Link to="/">Services</Link>
                                        <ul>
                                            <li><Link to="/page/quality-control">Quality Control</Link></li>
                                            <li><Link to="/page/service-policy">Service Policy</Link></li>
                                            <li><Link to="/page/rug-care">Rug Care</Link></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown" className={`${pathname.match('/himalayan-tibetan-wool-rugs') || pathname.match('/green-plant-fiber-nettle-allo-rugs') || pathname.match('/green-plant-fiber-hemp-rugs') || pathname.match('/green-plant-fiber-banana-silk-rugs') || pathname.match('/natural-chinese-silk-rugs') ? 'current' : ''}`}><Link to="/">Our Rugs</Link>
                                        <ul>
                                            <li><Link to="/page/himalayan-tibetan-wool-rugs">Himalayan wool rugs</Link></li>
                                            <li><Link to="/page/green-plant-fiber-nettle-allo-rugs">Green plant fiber - Nettle (allo) rugs</Link></li>
                                            <li><Link to="/page/green-plant-fiber-hemp-rugs">Green plant fiber - Hemp rugs</Link></li>
                                            <li><Link to="/page/green-plant-fiber-banana-silk-rugs">Green plant fiber - Banana silk rugs</Link></li>
                                            <li><Link to="/page/natural-chinese-silk-rugs">Natural chinese silk rugs</Link></li>
                                        </ul>
                                    </li>
                                    <li className={`${pathname.match('/price-list') ? 'current' : ''}`}><Link to="/page/price-list">Price List</Link></li>
                                    <li className={`${pathname.match('/contact-us') ? 'current' : ''}`}><Link to="/contact-us">Contact</Link></li>
                                </ul>
                            </div>
                        </nav>      
                    </div>
                    <div className="header-right clearfix">
                        <div className="button">
                            <Link className="btn-two" to="/contact-us"><span>Request a</span> Quote</Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
    )
}

export default Navbar
