import React,{useState,useEffect} from 'react'
import AdminSidebar from './AdminSidebar'
import {isAuthenticated} from '../auth'
import { addsetting } from './apiIndex'

import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from '../../components/editor/EditorToolbar'
import 'react-quill/dist/quill.snow.css'
import '../../components/editor/TextEditor.css'

const AddSetting = () => {
    
    const {token} =isAuthenticated()
    const [isChecked, setIsChecked] = useState(false);
  
    const [descvalue,setdescValue]=useState({
        setting_description:'',
    })
    const [bottom_menu_value,setbottom_menu_value]=useState({
        setting_permanent:0,
    })
    const [values,setValues]=useState({
        setting_name:'',
        setting_slug:'',
        setting_text:'',
        setting_image:'',
        error:'',
        success:false,
        formData:''
    })

    const {setting_description} = descvalue
    const {setting_permanent} = bottom_menu_value
    const {setting_name,setting_slug,setting_text,error,success,formData} = values
    //load set form data
     const init=()=>{
         setValues({...values,formData:new FormData})
     }
     //to send form data
     useEffect(()=>{
         init()
     },[])

    const ondescription = (value) => {
        const name='setting_description'
        formData.set(name,value)
        setdescValue({...descvalue,[name]:value})
    }

    const handleOnChange = (e) => {
        if(isChecked===false){
            const name='setting_permanent'
            formData.set(name,1)
            setbottom_menu_value({...bottom_menu_value,[name]:1})
        } else {
            const name='setting_permanent'
            formData.set(name,0)
            setbottom_menu_value({...bottom_menu_value,[name]:0})
        }
        setIsChecked(!isChecked);
    }

    const handleChange=name=>event=>{
    	switch (name) {
            case 'setting_slug':
                formData.set(name,event.target.value.toLowerCase().trim().split(' ').join('-'))
                setValues({...values,[name]:event.target.value.toLowerCase().trim().split(' ').join('-')})
                break;
	        case 'setting_image':
	            formData.set(name,event.target.files[0])
				      setValues({...values,[name]:event.target.files[0]})
	            break;
	        default:
	            formData.set(name,event.target.value)
				      setValues({...values,[name]:event.target.value})
	            break;
	      }
     }


     const handleSubmit=e=>{
         e.preventDefault()
         setValues({...values, error:''})
         addsetting(token,formData)
         .then(data => {
             if(data.error){
                 setValues({...values,error:data.error})
             }
             else{
                 setdescValue({...descvalue,setting_description:''})
                 setbottom_menu_value({...bottom_menu_value,setting_permanent:0})
                 setValues({...values,setting_name:'',setting_slug:'',setting_image:'',success:true,error:''})
                 setIsChecked(isChecked);
             }
         })
     }
   const [showImage, setShowImage] = useState(false);
   const [showTextarea, setShowTextarea] = useState(false);
   const [showText, setShowText] = useState(true);
    const setType = (e)=>{
        const name='setting_type'
        formData.set(name,e)
        setValues({...values,[name]:e})
        if(e.toString()==='image'){
            setShowImage(true);
            setShowText(false);
            setShowTextarea(false);
        } else if(e.toString()==='text'){
            setShowImage(false);
            setShowText(true);
            setShowTextarea(false);
        } else {
           setShowTextarea(true);
           setShowText(false);
           setShowImage(false);
        }
    }    
      // to show error msg

    const showError=()=>(
        <div className="alert alert-danger" style={{display:error?'':'none'}}>
            {error}
        </div>
    )

    //to show success msg
    const showSuccess=()=>(
        <div className="alert alert-info" style={{display:success?'':'none'}}>
       New setting added
    </div>
    )
    return (
        <>
        <div className="container dashboard-wrapper">
            <div className="row">
                <div className="col-md-3">
                    <AdminSidebar/>
                </div>
                <div className="col-md-6 adminpage-container">
                    <h3>Add Setting</h3>
                    <form className="shadow-lg p-3">
                        {showError()}
                        {showSuccess()}
                        <div className="mb-3">
                            <label htmlFor="settingname">Name</label>
                            <input type="text" id="settingname" className="form-control"
                            onChange={handleChange('setting_name')} value={setting_name}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="settingslug">Slug</label>
                            <input type="text" id="settingslug" className="form-control"
                            onChange={handleChange('setting_slug')} value={setting_slug}
                            />
                        </div>
                        <div className="mb-3">
                                <label htmlFor='category'>Type</label>
                                <select className="form-control" onChange={(e) => setType(e.target.value)} >
                                    <option value="">Select</option>
                                    <option value="text">Text</option>
                                    <option value="textarea">Textarea</option>
                                    <option value="image">Image</option>
                              </select>
                            </div>
                        { showText ? (
                        <>
                        <div className="mb-3">
                            <label htmlFor="settingdescription">Setting Value</label>
                            <input type="text" id="settingdescription" className="form-control"
                            onChange={handleChange('setting_text')} value={setting_text}
                            />
                        </div>
                        </>
                        ) : ('')}
                        { showTextarea ? (
                        <>
                        <div className="mb-3" id="setting_text">
                            <label htmlFor="description">Description</label>
                            <EditorToolbar toolbarId={'infodesc'} />
                            <ReactQuill
                              theme="snow"
                              value={setting_description || ''}
                              onChange={ondescription}
                              placeholder={"Write something awesome..."}
                              modules={modules('infodesc')}
                              formats={formats}
                            /> 
                        </div>
                        </>
                        ) : ('')}
                        { showImage ? (
                        <>
                        <div className="mb-3" id="setting_image">
                            <label htmlFor="image">Image</label>
                            <input type="file" id="image" className="form-control" accept="image/*"
                            onChange={handleChange('setting_image')} 
                             />
                        </div>
                        </>
                        ) : ('')}
                        
                        <div className="mb-3">
                            <input type="checkbox" id="setting_permanent" value={setting_permanent} checked={isChecked} onChange={handleOnChange}
                            /> 
                            Parmanent
                          </div>

                       <div className="mb-3">
                           <button className="btn btn-primary" onClick={handleSubmit}>Add Setting</button>
                       </div>

                    </form>
                </div>
            </div>
        </div>
            
        </>
    )
}

export default AddSetting
